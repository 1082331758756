.Layout {
  position: relative;
  height: 100vh;
}

.Layout_background {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, .2);
}