.Serie_list_related_title {
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  font-family: 'Heebo';
  margin-bottom: 30px;
}

.light .Serie_list_related_title {
  color: #fff
}

.Serie_list_related_ul {
  display: flex;
  justify-content: space-between;
  width: 500px;
}

.Serie_list_related_ul_li {
  font-size: 2rem;
  font-weight: 800;
  text-transform: uppercase;
  font-family: 'Heebo';
  margin-right: 20px;
  margin-bottom: 20px;
}


@media(max-width: 375px) { 
  .Serie_list_related_ul {
    flex-direction: column;
    justify-content: center !important;
    width: 100vw;
  }
  .Serie_list_related_ul_li {
    margin: auto !important;
  }
}

@media(max-width: 630px) { 
  .Serie_list_related_container {
    margin: 50px 10px 20px
  }
  .light.Serie_list_related_container {
    margin: 0 10px
  }
  .Serie_list_related_ul {
    width: 100%;
  }  
  .Serie_list_related_ul_li {
    margin-right: 10px;
  }
}

@media(min-width: 630px) and (max-width: 1155px) { 
  .Serie_list_related_container {
    display: none;
  }
}

@media(min-width: 630px) { 
  .Serie_list_related_ul {
    display: flex;
  }
} 