.ProjectStory_container {
  /* text-align: center; */
  height: 100%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  border: 1px solid red
}

.ProjectStory {
  padding: 80px 20px;
}

.ProjectStory__title {
  font-family: 'Montserrat';
  letter-spacing: 1.92px;
  font-weight: 600;
  color: rgb(144, 144, 144);
  font-size: 2.1rem;
  line-height: 1.6em;
}

.ProjectStory__text {
  font-family: 'Montserrat';
  letter-spacing: 1.92px;
  color: #000;
  font-size: 1rem;
  font-size: 1.85vh;
  line-height: 1.6em;
}

.ProjectStory__author {
  font-family: 'Heebo';
  font-weight: 300;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1.84px;
  margin-top: 20px
}


@media(min-width: 630px) {
  .ProjectStory_container {
    height: 100%;
    width: 110vh;
    display: flex;
    /* justify-content: center;
    align-items: center; */
  }
  .ProjectStory {
    padding: 130px;
  }
}