.Serie {
  width: 200px;
  cursor: pointer;
}

.Serie_art_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 200px;
  margin-bottom: 10px;
}

.Serie_art_container:hover .Serie_details_img {
  width: 210px
}

.Serie_details_button {
  margin: 15px auto 5px !important;
  font-size: 13px !important;
  transition: .3s;
}
.Serie:hover .Serie_details_button {
  opacity: 1;
}

.Serie_details_img {
  width: 100%;
  position: absolute;
  opacity: 1;
  transition: opacity 3s, width .6s, visibility 3s;
} 
.Serie_details_img.visible {
  opacity: 1;
}

.Serie_details_img.hide {
  opacity: 0;
  visibility: hidden;
}

.Serie_details_title {
  line-height: 15px;
  letter-spacing: .3px;
  font-weight: 300;
  font-family: 'Heebo';
  text-align: center;
}

.light .Serie_details_title {
  color: #fff
}

.Serie_details_title {
  font-size: .8rem;
  text-transform: uppercase;
}

.Serie_details_container {
  position: relative;
  padding: 5px;
  margin: 20px 10px 30px
}

.Serie_showcase {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}

@media(min-width: 1000px) { 
  .Serie_details_container {
    margin: auto
  }
  .Serie_details_button {
    opacity: 1;
  }
}