.Quote_container {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Quote {
  padding: 80px 20px;
}

.Quote__text {
  font-family: 'Libre Baskerville';
  letter-spacing: 1.92px;
  color: rgb(255, 244, 194);
  font-style: italic;
  font-size: 1.4rem;
  line-height: 2rem;
}

.Quote__author {
  font-family: 'Heebo';
  font-weight: 300;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1.84px;
  margin-top: 20px
}


@media(min-width: 630px) {
  .Quote_container {
    text-align: center;
    height: 100%;
    width: 110vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Quote {
    padding: 150px;
    padding: 180px;
  }
}