.Header {
  text-align: center;
  width: 100vw;
  min-height: 100vh;
}

.Header_main {
  margin-top: 140px;
  margin-bottom: 50px;
}

.Header_title {
  margin-bottom: 10px;
}
.Header_title_name {
  display: inline-block;
  word-break: break-all;
  font-size: 1.55rem;
  font-weight: 800;
  margin-right: 12px;
  text-transform: uppercase;
  font-family: 'Heebo';
}

.Header_title_name::first-letter {
  font-size: 2rem;
}

.Header_subtitle {
  margin-top: 20px;
  font-weight: 300;
  line-height: 18px;
  font-size: .9rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'Heebo';
}

.Header_series_list {
  margin-top: 80px;
  list-style: none;
  width: 100%;
  max-width: 1000px;
}

.Header_series_list_item {
  margin: 0 auto 20px;
  width: 200px;
  cursor: pointer;
  transition: 1s;
}
.Header_series_list_item:hover {
  opacity: .7;
  transition: .5s;
}

.Header_artist {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 800;
  font-family: 'Heebo';
  /* text-transform: uppercase; */
  color: rgb(209, 209, 209);
}

@media(min-width: 1000px) {
  .Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 0
  }

  .Header_main {
    margin: 0;
  }

  .Header_series_list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .Header_series_list_item {
    width: 200px;
  }
}