.Footer {
  position: relative;
  padding: 50px 10px 10px;
}

.Footer_main {
  padding: 0 30px;
}

.Footer_contact {
  width: 100%;
  max-width: 1050px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}


.Footer_info_container { 
  color: #000;
  text-align: right;
  font-size: .7rem;
  font-family: 'Heebo';
  font-weight: 300; 
}

.Footer_rrss_container { 
  color: #000;
  margin-bottom: 90px;
}

.Footer_contact_info {
  max-width: 300px;
}

.Footer_contact_info-title {
  color: #000;
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'Heebo';
  font-weight: 200;
  margin-bottom: 30px; 
}
.Footer_contact_info-description {
  color: #000;
  font-size: 16px;
  line-height: 22px;
  font-family: 'Heebo';
  font-weight: 300; 
  margin-bottom: 35px;
}

@media(max-width: 375px) { 
  .Footer {
    margin-top: 60px;
    border-left: none;
  }
  .Footer.hide_series {
    padding: 0 !important;
    margin:  0 !important;
  }
  .Footer.hide_series .Footer_info_container {
    height: 100%;
    padding: 10px 20px 30px;
    text-align: right;
  }
}

@media(min-width: 375px) { 
  .Footer.hide_series {
    margin: 0;
    padding: 0; 
  }
  .Footer.hide_series .Footer_info_container {
    height: min-content;
    padding: 10px 10px;
    /* color: #fff */
  }
}

@media(max-width: 630px) { 
  .Footer_main {
    /* flex-direction: column-reverse; */
    /* justify-content: center; */
    /* padding: 0; */
    /* width: 100%; */
  }
  .Footer_row .Footer_main {
    /* flex-direction: column; */
  }
  .Footer.hide_series {
    margin: 0;
  }
  .Footer_contact {
    height: auto;
    padding: 40px 0;
    /* width: 100%; */
  }  
  .Footer_info_container {
    margin-bottom: 20px;
    margin-top: 100px;
  }
}

@media(min-width: 630px) and (max-width: 1155px) { 
  .Footer_main {
    /* justify-content: center; */
  }
}

@media(min-width: 630px) { 
  .Footer {
    width: 100vw;
    padding: auto
  }
  .Footer_related_ul {
    display: flex;
  }
  .Footer_info_container {
    position: absolute;
    bottom: 20px;
    right: 35px;
    text-align: right;
  }

  .Footer_main {
    margin-bottom: 90px;
  }

  /* .Footer_row.show_series .Footer_contact {
    height: 370px;
  }
  .Footer_row .Footer_contact {
    padding: 10px 50px 40px;
    width: 400px;
  } */
  .Footer.Footer_row {
    padding-top: 90px;
    margin-left: 150px;
  }
  .Footer_row .Footer_main {
    /* display: flex; */
    /* justify-content: space-between; */
    /* padding: 0 50px; */
  }
  .Footer.hide_series {
    margin: 0;
    padding: 30px 0;
  }
  .Footer_rrss_container { 
    bottom: 14px;
    left: 40px;
  }
  
} 
