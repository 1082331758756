.MoveButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.MoveButton-circle {
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.MoveButton-circle span{ 
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  height: 100%;
  width: 100%;
  border: 1px solid #000;
  border-radius: 50%;
  opacity: .3;
}

.animation-1 .MoveButton-circle span{ 
  animation: 2.2s linear forwards infinite deform
}
.animation-1 .MoveButton-circle span:nth-child(2) {
  animation: 2.6s linear reverse forwards infinite deform2;
}
.animation-1 .circle span:nth-child(3) {
  animation: 2.4s linear forwards infinite deform3;
}

.animation-2 .MoveButton-circle span{ 
  animation: 2.2s linear forwards infinite deform2
}
.animation-2 .MoveButton-circle span:nth-child(2) {
  animation: 2.6s linear reverse forwards infinite deform;
}
.animation-2 .circle span:nth-child(3) {
  animation: 2.4s linear forwards infinite deform3;
}

.MoveButton-btn {
  cursor: pointer;
  background: none;
  color: #000;
  text-align: center;
  outline: none;
  padding: 21px;
  border: none
}


@keyframes deform {
  0% {
    border-radius: 50%;
    transform: rotate(0deg);
  }
  30% {
    border-radius: 46%;
    transform: rotate(60deg);
  }
  40% {
    border-radius: 54%;
    transform: rotate(100deg);
  }
  50% {
    border-radius: 46%;
    transform: rotate(160deg);
  }
  60% {
    border-radius: 54%;
    transform: rotate(220deg);
  }
  70% {
    border-radius: 50%;
    transform: rotate(260deg);
  }
  80% {
    border-radius: 44%;
    transform: rotate(320deg);
  }
  100% {
    border-radius: 50%;
    transform: rotate(360deg);
  }
}

@keyframes deform2 {
  0% {
    border-radius: 56%;
    transform: scale3d(0.95, 1.05, 1) rotate(0deg);
  }
  30% {
      border-radius: 50%;
      transform: scale3d(1.02, 0.95, 1) rotate(120deg);
  }
  40% {
      border-radius: 46%;
      transform: scale3d(1, 0.85, 1) rotate(360deg);
  }
  50% {
      border-radius: 58%;
      transform: scale3d(0.92, 1.07, 1) rotate(0deg);
  }
  60% {
      border-radius: 54%;
      transform: scale3d(1.02, 1, 1) rotate(220deg);
  }
  70% {
      border-radius: 48%;
      transform: scale3d(0.95, 1.02, 1) rotate(260deg);
  }
  80% {
      border-radius: 52%;
      transform: scale3d(0.9, 1.02, 1) rotate(320deg);
  }
  100% {
      border-radius: 56%;
      transform: scale3d(0.95, 1.05, 1) rotate(360deg);
  }
}

@keyframes deform3 {
  0% {
    border-radius: 46%;
    transform: scale3d(1, 1.04, 1) rotate(0deg);
  }
  30% {
      border-radius: 50%;
      transform: scale3d(0.95, 1, 1) rotate(120deg);
  }
  40% {
      border-radius: 56%;
      transform: scale3d(1.02, 0.95, 1) rotate(360deg);
  }
  50% {
      border-radius: 50%;
      transform: scale3d(1, 1.04, 1) rotate(0deg);
  }
  60% {
      border-radius: 48%;
      transform: scale3d(1.02, 0.95, 1) rotate(220deg);
  }
  70% {
      border-radius: 54%;
      transform: scale3d(0.95, 1.02, 1) rotate(260deg);
  }
  80% {
      border-radius: 50%;
      transform: scale3d(0.9, 1.05, 1) rotate(320deg);
  }
  100% {
      border-radius: 46%;
      transform: scale3d(1, 1.054, 1) rotate(360deg);
  }
}