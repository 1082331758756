.Exhibitions {
  overflow: hidden;
}
.Exhibitions_main {
  margin: -10px 50px 50px 50px;
  min-height: 100vh;
}

.Exhibitions_time-container {
  margin: 80px auto 100px;
  max-width: max-content;
}

.Exhibitions_title {
  font-family: 'Heebo';
  font-weight: bold;
  font-size: 16px;
  margin-right: 3px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-right: 8px;
}
.Exhibitions_text {
  font-family: 'Heebo';
  font-weight: 100;
  letter-spacing: .98px; 
  line-height: 24px;
}

.Exhibitions_img-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Exhibitions_main-img{
  filter: grayscale(1);
  -webkit-filter: grayscale(1); 
  filter: grayscale(100%); 
  margin: 2px;
}

.Exhibitions_main-img.kinema,
.Exhibitions_main-img.mairena {
  height: 140px;
}

@media(min-width: 658px) {
  .Exhibitions_main-img.kinema,
  .Exhibitions_main-img.mairena {
    height: 200px;
  }
}
@media(min-width: 800px) {
  .Exhibitions_main-img.kinema,
  .Exhibitions_main-img.mairena {
    height: 300px;
  }
}
