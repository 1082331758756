
.Project_header_subtitle {
  font-size: 2.3vh;
}

.Project_header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: calc(100vh - 100px);
  padding: 10px;
  color: rgb(255, 254, 243);
  overflow: hidden;
}

.Project_header_mask {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  filter: brightness(60%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Project_header_title {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Heebo';
  letter-spacing: 1.92px;
}

.Project_header_subtitle {
  font-size: 18px;
  font-weight: 300;
}

.Project_work {
  display: flex;
  justify-content: center;
  margin: 30px auto;
}

.Project_works_img {
  width: auto;
}

.Project_header_scroll_indicator {
  display: none;
}

@media(min-width: 630px) {
  .Project_header_title {
    font-size: 3.5rem;
  }
  .Project {
    display: flex;
    overflow-y: hidden;
    height: 100vh
  }

  .Project_main {
    width: min-content;
    display: flex;
  }

  .Project_header {
    width: calc(100vw - 150px);
    padding: 30px;
    height: auto;
  }

  .Project_works_container {
    padding-top: 20px;
    margin: 60px auto;
    position: relative;
    display: flex;
    margin: auto
  }
  
  .Project_works {
    display: flex;
    height: 100%;
  }

  .Project_work {
    align-items: center;
    width: 800px;
  }

  .Project_works_img {
    width: 400px;
  }

  .Project_header_scroll_indicator {
    display: flex;
    align-items: center;
    position: absolute;
    right: 50px;
    bottom: 50px;
  }

  .Project_header_scroll_indicator__line {
    width: 65px;
    height: 1px;
    overflow: hidden;
  }
  
  .Project_header_scroll_indicator__line::before {
    content: ' ';
    display: block;
    height: 100%;
    background: #fff;
    animation: scrollIndicator 3s cubic-bezier(.4,0,.2,1) 1s infinite normal;
  }

  .Project_header_scroll_indicator__label {
    font-weight: 300;
    margin-left: 10px;
    font-size: 16px;
    font-family: 'Heebo';
    color: #fff
  }
}

@keyframes scrollIndicator {
  0% { width: 0 }
  30% {
    width: 100%;
    transform: translateX(0);
  }
  90%, 100% { transform: translateX(100%) }
}