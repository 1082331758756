.first-line,
.first-line::before {
  height: 100%;
}

.sidedrawer-button {
  cursor: pointer;
  width: 85%;
  height: 5px;

  position: relative;
  overflow: hidden;
  transition-delay: .2s;
}

.first-line {
  position: absolute;
}

.first-line::before { 
  content: ' ';
  position: absolute;
  transform: translateX( calc((100%*2) * -1 ));
}

.first-line,
.first-line::before{
  transition: 1.5s;
  background: rgb(209, 209, 209);
  width: 100%;
  top: 0;
}

.sidedrawer-button.active .first-line{
  opacity: 1;
  transition: transform 1s cubic-bezier(.17,.67,.83,.67) 0s;
  transform: translateX(calc((100%*2)));
  background: #000;
}
.sidedrawer-button.active .first-line::before {
  transition: 1s;
  background: #000;
}
