
.Project_details_project_works_container {
  margin: 60px auto;
}

.Project_details_project_work {
  margin: 30px auto;
}

.Project_details_project_works_img {
  width: auto;
}

.Project_details_about {
  height: 300px;
}

.Project_details_main_description_info {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 300;
}

@media(min-width: 630px) {
  .Project_details_project_main {
    height: auto;
  }
  .Project_details_project {
    min-height: 100vh;
  }
  
  .Project_details_project_works_container {
    margin: 70px 100px;
    margin: 70px 0;
    position: relative;
  }

  .Project_details_project_works_img {
    width: 400px;
  }
  
}





/*  */

.Hypnopompic_project {
  display: flex;
  overflow-y: hidden;
  height: 100vh
}

.Hypnopompic_project_main {
  width: min-content;
  display: flex;
}

.Hypnopompic_header_title {
  font-size: 7.4vh;
  margin-bottom: 0;
}

.Hypnopompic_header_subtitle {
  font-size: 2.3vh;
}

.Hypnopompic_project_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100vw - 150px);
  padding: 30px;
  color: rgb(255, 254, 243);
  background: rgb(204, 176, 146);
}

.Hypnopompic_project_header_title {
  font-size: 32px;
  font-weight: 800;
  text-transform: uppercase;
  font-family: 'Heebo';
}

.Hypnopompic_project_header_subtitle {
  font-size: 18px;
  font-weight: 300;
  font-family: 'Libre Baskerville';
}

.Hypnopompic_project_works_container {
  display: flex;
}

/* pasar a un componente */
.Project_details_work {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_details_works_img {
  display: block;
  width: 100%;
}


.Project_details_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px
}

.Project_details_main_description {
  max-width: 350px;
  margin: 30px 10px 50px 10px;
  text-align: left;
  width: 100%;
  align-self: flex-start;
}

.Project_details_main_description_project {
  font-size: 24px;
  font-weight: 800;
  font-size: 28px;
  opacity: .1;
  font-family: 'Heebo';
}

.Project_details_main_description_title {
  font-size: 24px;
  font-weight: 800;
  font-size: 28px;
  font-family: 'Heebo';
}

.Project_details_main_description_project::first-letter,
.Project_details_main_description_title::first-letter {
  text-transform: uppercase;
}

.Project_details_main_description_subtitle {

}

.Project_details_main_description_size {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 800;
  font-family: 'Heebo';
}

.Project_details_main_description_materials {
  margin: 20px 0 5px;
  font-size: 14px;
  font-weight: 800;
  font-family: 'Heebo';
}
.Project_details_main_description_material_details {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Heebo';
}
.Project_details_main_description_material_details::first-letter {
  text-transform: uppercase;
}

.Project_details_main_img_container {
  position: relative;
}
.Project_details_showcase {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}

@media(min-width: 830px) { 
  .Project_details_main {
    flex-direction: row-reverse;
    align-items: flex-start;
  }
  .Project_details_main_description {
    margin: 0;
    position: sticky;
    top: 130px;
    height: max-content;
    margin-left: 50px;
    width: 600px;
    margin-bottom: 30px;
    max-width: 350px;
  }
}
@media(min-width: 630px) { 
  .Project_details_work {
    display: flex;
    align-items: center;
    width: 800px;
  }
  .Project_details_main_img_container {
    width: 590px;
    height: calc(100vh - 100px);
  }
  .Project_details_works_img {
    width: auto;
    max-height: calc(100vh - 100px);
  }
  .Project_details_serie_list {
    margin: 120px 50px 100px;
  }
  
}