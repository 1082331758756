.Carousel-container {
  margin: 100px 0;
  width: 100vw;
}

.Carousel-container .react-multi-carousel-track  {
  padding-bottom: 40px;
}

.Carousel-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  height: 150px;
  overflow: hidden;
  opacity: 1;
  transition: .8s;
}

.Carousel-item:hover {
  opacity: .6;
}

.react-multi-carousel-dot button {
  transition: all .8s;
  border-width: 1px !important;
  border-color: rgb(167, 167, 167);
  background: transparent;
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button{
  border-color: #fff;
  background: var(--grey);
  background: rgb(167, 167, 167);
}

.Carousel-item-img {
  width: 100%;
}

.Carousel-item-img-showcase {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}

@media(min-width: 700px) {
  .Carousel-item {
    height: 250px;
  }
}
@media(min-width: 865px) {
  .Carousel-item {
    width: 200px;
  }
}
