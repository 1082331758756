.Form-container {
  margin-top: 10px;
  height: 500px;
  max-width: 550px;
}

.Form_contact_terms_container {
  margin-top: 1.2rem;
}

.Form_contact_name_inputs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Form_contact_input_container { 
  min-height: 70px;
  margin-bottom: 20px;
  width: 100%;
}

.Form-container .Form_contact_input_textarea,
.Form_contact_input {
  padding: 1.25rem 1.2rem;
  width: 100%;
  border: none;
  box-sizing: border-box;
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 300;
  font-family: 'Heebo';
  outline: none;
  background-color: rgba(246,246,246);
}

.Form-container .Form_contact_input_textarea {
  resize: none;
  height: 150px;
}

.Form_contact_input_label {
  display: block;
  font-family: 'Heebo';
  letter-spacing: 1px;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 15px;
}

.Form_contact_input_textarea::placeholder,
.Form_contact_input::placeholder {
  opacity: .8;
}

.Form_contact_terms_input {
  position: absolute;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.Form_contact_terms_button_container {
  display: flex;
  align-items: center;
}

.Form_contact_terms_custom_checkbox {
  position: relative;
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-right: 6px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #000
}

.Form_contact_terms_custom_checkbox::after {
  content: ' ';
  display: none;
  position: absolute;
  width: 9px;
  height: 9px;
  background: #000;
  border-radius: 50%;
}

.Form_contact_terms_input.checked ~ .Form_contact_terms_custom_checkbox::after {
  display: block;
}

.Form_contact_terms {
  font-size: .65rem;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: .03px;
  color: #000;
  font-family: 'Heebo';
}

.Form_contact_terms a {
  color: #000;
  text-decoration: underline;
  cursor: pointer;
}

.Form_contact_terms_accept {
  cursor: pointer;
}

.Form_info_container {
  font-size: .8rem;
  padding: 5px;
  font-weight: 400;
  line-height: 16px;
  font-family: 'Heebo';
  padding: 10px;
}

.Form_contact_error_message {
  display: block;
  font-family: 'Heebo';
  color: rgb(201, 2, 2);
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
}

.Form_contact_succeed_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 250px;
  width: max-content;
  width: 100%;
  background-color: rgba(246,246,246);
}

.Form_contact_succeed_message {
  font-family: 'Heebo';
  font-weight: 300;
  font-size: 16px;
  padding: 0 50px;
  line-height: 18px;
  letter-spacing: .3px;
  margin-bottom: 15px;
  color: #000;
}

@media(max-width: 375px) { 
  .Form_contact_input_textarea {
    padding: 20px 10px !important;
  }  
}

@media(min-width: 550px) { 
  .Form_contact_name_inputs .Form_contact_input_container:first-child {
    padding-right: 60px;
  }
  .Form_contact_input_container {
    width: auto;
  }
}

@media(min-width: 630px) { 
  .Form_contact_container {
    max-width: 500px;
    width: 100%;
  }
  .Form_contact_succeed_message {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

