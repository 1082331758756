.Social_networks {
  display: flex;
  margin-bottom: 10px;
}

.Social_networks_align_left {
  justify-content: flex-start;
}

.Social_networks_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.Social_networks_icon_container .Social_networks_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  transition: .5s;
  opacity: .5;
}

.Social_networks_icon_container:hover .Social_networks_icon {
  opacity: .8;
}