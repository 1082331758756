.About_main {
  min-height: 100vh;
  margin: 100px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.About_main_area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.About_main p {
  font-family: 'Heebo';
  font-weight: 100;
  letter-spacing: .98px; 
  line-height: 24px;
}

.About_main_statement,
.About_main_bio {
  max-width: 600px;
  margin: 10px;
}

.About_main_title {
  font-family: 'Heebo';
  font-weight: bold;
  font-size: 16px;
  margin-right: 3px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.About_main_bio_description {
  margin-bottom: 15px;
}

@media(min-width: 1200px) {
  .About_main {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    max-width: 1300px;
    margin: 0 auto;
  }
  .About_main_area {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
  }
}