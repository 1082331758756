.Art_work_item_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Art_work_item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 100%;
  cursor: pointer;
}

.Art_work_item_link {
  display: flex;
  flex-direction: column-reverse;
}

.Art_work_item span {
  display: block;
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: .8px;
  text-transform: lowercase;
  text-align: center;
}

.Art_work_item span::first-letter {
  text-transform: capitalize;
}

.Art_work_item_img {
  width: 100%;
}

.Art_work_item .Art_work_item_title {
  font-weight: bold;
  font-family: 'Heebo';
  font-size: .8rem;
  text-transform: uppercase;
  line-height: 14px;
  letter-spacing: .5px;
  transition: .3s;
  margin-left: 5px;
}


.Art_work_item_title_container {
  margin: 15px 0;
}

.Art_work_item_title_area {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
}

.Art_work_item_view_more {
  transition: .5s;
  opacity: 0;
}

.Art_work_item:hover .Art_work_item_view_more {
  opacity: 1;
}

.Art_work_item:hover .Art_work_item_title {
  font-weight: 800;
}

.Art_work_item_showcase {
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  background: transparent;
}

@media(min-width: 630px) {
  .Art_work_item_container {
    width: 800px;
  }
  .Art_work_item {
    padding-bottom: 50px;
  }
  .Art_work_item_link {
    flex-direction: column;
  }
  .Art_work_item_img_container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 80vh;
  }
  .Art_work_item_img {
    position: absolute;
    transition: .3s;
    filter: drop-shadow(10px 10px 22px rgba(0, 0, 0, .3))
  }
  .Art_work_item:hover .Art_work_item_img_container {
  }
  .Art_work_item:hover .Art_work_item_img {
    width: 410px;
    filter: drop-shadow(10px 10px 22px rgba(0, 0, 0, .6))
  }
  .Art_work_item:hover .Art_work_item_showcase {
    width: 410px;
  }
  .Art_work_item_title_container {
    position: absolute;
    bottom: 0;
    margin: auto;
    width: 100%;
  }
}
