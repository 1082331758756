.Studio_main {
  margin: 140px 50px 50px 50px;
  min-height: 100vh;
}

.Studio-header {
  /* min-height: 100vh; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.Studio-video {
  width: 100vw;
  margin: 100px auto;
  display: block;
  filter: grayscale(1);
  -webkit-filter: grayscale(1); 
  filter: grayscale(100%); 
}

@media(min-width: 480px) {
  .Studio-video {
    width: 80vw;
  }

  .Studio-header {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

@media(min-width: 850px) {
  .Studio-video {
    width: auto;
    max-height: 450px;
  }
}

@media(min-width: 1600px) {
  .Studio-video {
    max-height: 650px;
  }
}