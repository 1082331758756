.Cookies_container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  visibility: visible;
  transition: .8s;
  opacity: 1;
}

.Cookies_container.hide {
  opacity: 0;
  visibility: hidden;
}

.Cookies_main {
  display: flex;
  justify-content: space-between;
  background: rgba(246,246,246);
  padding: 2rem 2.75rem;
}

.Cookies_text {
  font-weight: 400;
  line-height: 15px;
  letter-spacing: .3px;
  font-family: 'Heebo';
  font-size: .87rem;
}

.Cookies_close {
  border: none;
  background: transparent;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: .3px;
  font-family: 'Heebo';
  font-size: 1.1rem;
  cursor: pointer;
  transition: .8s;
}

.Cookies_close:hover { 
  opacity: .6;
}

@media(max-width: 500px) {
  .Cookies_main {
    display: block;
  }
  .Cookies_close {
    margin-top: 20px;
    padding: 0;
  }
}