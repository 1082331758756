.FooterNav_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0 2rem;
  margin: 0 6rem;
  border-top: 1px solid rgb(216, 216, 216)
}

.FooterNav_container.hide {
  display: none;
}

.FooterNav_title_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  padding-top: 20px;
}

.FooterNav_nav_container {
  width: 240px;
}

.FooterNav_title {
  margin-top: 30px;
  display: inline-block;
  letter-spacing: .3px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Heebo';
  font-size: 20px;
  margin-right: 10px;
}

.FooterNav_title::first-letter {
  font-size: 25px;
}

.FooterNav_logo {
  width: 65px;
}

.FooterNav_nav_li_ul {
  padding-left: 10px;
}

.FooterNav_nav_li a,
.FooterNav_nav_li_ul_item {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: .3px;
  font-weight: 300;
  font-family: 'Heebo';
  cursor: pointer;
  transition: .8s;
  white-space: nowrap;
}

.FooterNav_nav_li a:hover,
.FooterNav_nav_li_ul_item:hover {
  opacity: .6;
}

.FooterNav_nav_li {
  margin-bottom: 5px;
}
.FooterNav_nav_li::first-letter {
  text-transform: uppercase;
}

.FooterNav_nav_li_ul_item {
  padding-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.FooterNav_nav_li_ul_item::before {
  content: "\2022"; 
  color: #000;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

@media(min-width: 600px) {
  .FooterNav_container {
    flex-direction: row;
  }
  .FooterNav_nav {
    border-left: 1px solid rgb(216, 216, 216);
    padding: 2px 1rem 2px 4rem;
  }
  .FooterNav_title_container {
    padding: 2px 4rem 2px 2rem;
  }
}