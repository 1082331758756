
.Progress_bar_container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 9px;
  left: 0;
  right: 0;
}

.Progress_bar_box {
  height: 4px;
  width: 300px;
  margin: 0 20px;
  border: 1px solid #000;
  background: #fff;
  background: transparent;
  overflow: hidden;
}

.Progress_bar {
  height: 4px;
  background: #000;
  opacity: 1;
  transition: .5s
}

.Progress_bar__visible {
  transition: .5s
}

.Progress_bar__hide {
  opacity: 0;
  transition: .5s
}

.Progress_bar-nav {
  font-weight: 300;
  line-height: 18px;
  font-size: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'Heebo';
  cursor: pointer;
  transition: 1s;
}

.Progress_bar-nav:hover {
  opacity: .6;
}