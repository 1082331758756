.Page404_main {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Page404_main_area {
  text-align: center;
}

.Page404_main_block button{
  margin: 40px auto 0
}

.Page404_main p {
  font-family: 'Heebo';
  font-weight: 100;
  font-size: 22px;
  letter-spacing: .98px; 
  line-height: 24px;
}

.Page404_main_title {
  font-family: 'Heebo';
  font-weight: bold;
  font-size: 16px;
  margin-right: 3px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.Page404_main_description {
  margin-bottom: 15px;
}

