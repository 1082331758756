
.SideDrawer {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background: #fff;
  transition: .5s all;
  transition: .8s;
  z-index: 99;
  visibility: visible;
}

.SideDrawer_background-image {
  position: absolute;
  bottom: -5px; 
  right: -58px;
  width: 350px;
  opacity: .3;
}

.SideDrawer.close {
  transform: translateX(100vw);
  visibility: hidden;
}

.SideDrawer_close_container {
  margin: 20px 10px;
}

.SideDrawer_close {
  margin: 0 0 0 auto;
  width: min-content;
  font-size: 1.2rem;
  font-weight: 800;
  text-transform: uppercase;
  font-family: 'Heebo';
  cursor: pointer;
}

.SideDrawer_close:hover {
  opacity: .8;
}

.SideDrawer_main {
  width: 100%;
  z-index: 1
}

.SideDrawer_body {
  font-size: .8rem;
  font-weight: 800;
  text-transform: uppercase;
  font-family: 'Heebo';
  line-height: 14px;
  letter-spacing: .2px;
}

.SideDrawer_body_serie {
  font-size: .9rem;
}

.SideDrawer_series_list {
  list-style: circle;
}

.SideDrawer_series_list_item {
  margin: 20px;
  width: max-content;
}

.SideDrawer_series_list_project_item {
  transition: .8s;
  margin: 20px;
  width: max-content;
}

.SideDrawer_series_list_project_item:hover {
  opacity: .6;
}