.DefaultButton {
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-family: 'Heebo';
  font-size: 22px;
  letter-spacing: .9px;
  font-weight: 200;
  color: #000;
  /* text-transform: uppercase; */
  background-color: transparent;
  transition: .8s;
  display: block;
  line-height: 22px;
  margin: 40px 0 20px;
}
.DefaultButton span{
  opacity: .9;
}
.DefaultButton:hover span{
  opacity: 1;
}

.DefaultButton:active {
  color: rgb(81, 185, 145);
}

@media(min-width: 630px) { 
  .DefaultButton {
    margin: 30px 0 0
  }
}