.Navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  z-index: 10;
  height: 50px;
}

.Navbar_left {
  display: flex;
  align-items: center;
}

.Navbar_artist_name {
  display: inline-block;
  font-family: 'Heebo';
  font-weight: bold;
  font-size: 13px;
  margin-right: 3px;
  text-transform: uppercase;
  text-shadow: -1px 1px rgba(255, 255, 255, .02), 0 1px rgba(255, 255, 255, .02), 1px 0 rgba(255, 255, 255, .02), 0 -1px rgba(255, 255, 255, .02);
}

.Navbar_artist_name::first-letter {
  font-size: 15px;
}

.Project_project_go_back span { 
  font-weight: 800;
  cursor: pointer;
  color: #000;
  font-family: 'Heebo';
  font-weight: bold;
  font-size: 13px;
  margin-right: 3px;
  text-transform: uppercase;
}

.Project_project_go_back span:hover {
  opacity: .8;
} 


.Navbar_right_ul {
  display: flex;
  list-style: none;
}

.Navbar_right_ul_li {
  margin-left: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.Navbar_right_ul_li span {
  font-family: 'Heebo';
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid transparent;
  text-shadow: -1px 1px rgba(255, 255, 255, .02), 0 1px rgba(255, 255, 255, .02), 1px 0 rgba(255, 255, 255, .02), 0 -1px rgba(255, 255, 255, .02);
}

.Navbar_right_ul_li.Navbar_desktop {
  display: none;
}

.Navbar_right_ul_li.Navbar_mobile {
  display: block;
}

.Navbar_artist_artist_logo_container {
  cursor: pointer;
}

.backgound_active {
  background: #fff;
  transition: .3s ;
  border-bottom: 1px solid rgba(0, 0, 0, .5)
}

.backgound_disable {
  transition: .3s;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  background: transparent;
}

@media(min-width: 800px) {
  .Navbar {
    padding: 0 30px;
  }
  .Navbar_right_ul_li.Navbar_desktop {
    transition: .5s;
    display: block;
  }
  .Navbar_right_ul_li.Navbar_desktop:hover {
    opacity: .6;
  }
  .Navbar_right_ul_li.Navbar_mobile {
    display: none;
  }
}